const Logo = () => {
  return (
    <div className="flex items-center space-x-2 select-none">
      <span className="text-lg font-bold text-gray-1 font-orbitron tracking-wider">
        Linguaphoto
      </span>
    </div>
  );
};

export default Logo;
